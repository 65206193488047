import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { isDefaultCurrency } from "@/lib/app";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Database,
  NestedCurrency,
  NestedExchangeRate,
  SignedInCompany,
} from "@revelate/types";
import { Pencil } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import DatePicker from "./date-picker";
import { KeyedMutator } from "swr";
import { useState } from "react";
import { upsertExchangeRate } from "@/lib/supabase/exchange_rates";

const profileFormSchema = z.object({
  rate: z.string({
    required_error: "Please enter a target value.",
  }),
  valid_to: z.date().optional(),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

export function ExchangeRatesForm({
  company,
  currency,
  exchangeRate,
  mutate,
}: {
  company: SignedInCompany;
  currency: NestedCurrency;
  exchangeRate: NestedExchangeRate | null;
  mutate: KeyedMutator<NestedCurrency[]>;
}) {
  const [open, setOpen] = useState(false);

  const defaultValues: Partial<ProfileFormValues> = {
    rate: exchangeRate?.rate.toString() || undefined,
    valid_to: exchangeRate?.valid_to
      ? new Date(exchangeRate?.valid_to)
      : undefined,
  };

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: "onChange",
  });

  async function onSubmit(data: ProfileFormValues) {
    if (!company.default_currency_id) return;
    const exchangeRateData: Database["public"]["Tables"]["exchange_rates"]["Insert"] =
      {
        id: exchangeRate?.id,
        company_id: company.id,
        from_currency_id: exchangeRate?.from_currency_id || currency.id,
        to_currency_id:
          exchangeRate?.to_currency_id || company.default_currency_id,
        rate: Number(data.rate.replace(",", ".")),
        valid_to: data.valid_to ? data.valid_to.toISOString() : null,
      };
    const insertedExchangeRate = await upsertExchangeRate(exchangeRateData);
    if (!insertedExchangeRate) return;
    mutate();
    setOpen(false);
  }

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>Error: {error.message}</div>;
  // }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {!isDefaultCurrency(company, currency.id) && (
          <Button variant="default" size="icon" className="bg-[#6B6397]">
            <Pencil className="h-4 w-4" />
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <DialogHeader>
              <DialogTitle>
                {" "}
                {currency.code} to{" "}
                {company.default_currency?.code || "default currency"} exchange
                rate
              </DialogTitle>
              <DialogDescription>
                Add or update the exchange rate to sync deals in {currency.code}
                .
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <FormField
                control={form.control}
                name="rate"
                render={({ field }) => (
                  <FormItem>
                    {/* <Label htmlFor="name" className="text-right">
                        1 EUR =
                      </Label> */}
                    <div className="grid grid-cols-4 items-center gap-2">
                      <FormLabel className="text-right">
                        1 {currency.code} =
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder={`Value in ${
                            company.default_currency?.code || "default currency"
                          }`}
                          className="col-span-3"
                          {...field}
                        />
                      </FormControl>
                      <div className="col-span-4">
                        <FormMessage className="text-xs text-right" />
                      </div>

                      {/* <FormDescription className="text-xs">
                        Select calendar months in which the goal is valid.
                      </FormDescription> */}
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="valid_to"
                render={({ field: { onChange, value } }) => (
                  <FormItem>
                    {/* <Label htmlFor="name" className="text-right">
                        1 EUR =
                      </Label> */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <FormLabel className="text-right"> Valid until</FormLabel>
                      <FormControl>
                        <DatePicker date={value} setDate={onChange} />
                      </FormControl>
                      {/* <FormMessage /> */}

                      {/* <FormDescription className="text-xs">
                        Select calendar months in which the goal is valid.
                      </FormDescription> */}
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <Button type="submit">Update</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
