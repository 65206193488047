// import Image from "next/image";
// import Link from "next/link";

import { Card, CardContent } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { getFriendlyNameOfMonths } from "@revelate/utils";
import { Pencil } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useLocation } from "wouter";
import { NestedQuota } from "@revelate/types";
import { ConfirmDeletionDialog } from "../confirm-deletion";
import type { KeyedMutator } from "swr";
import { getQuotaUnitFromValue, getTimePeriodFromDateRange } from "@/lib/app";
import { Badge } from "@/components/ui/badge";
import { deleteGoal } from "@/lib/supabase/goals";
import { UserAvatarList } from "@/components/UserAvatarList";

export function GoalsTable({
  quota,
  mutate,
}: {
  quota: NestedQuota;
  mutate: KeyedMutator<NestedQuota | null>;
}) {
  const { goals } = quota;
  const [, setLocation] = useLocation();

  return (
    <Card x-chunk="dashboard-05-chunk-3">
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="hidden sm:table-cell">
                Target value
              </TableHead>
              <TableHead className="hidden sm:table-cell">
                Time period
              </TableHead>
              <TableHead className="hidden sm:table-cell">Validity</TableHead>
              <TableHead className="hidden sm:table-cell">Employees</TableHead>

              <TableHead className="text-right"></TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {goals?.map((goal, index) => (
              <TableRow key={index}>
                <TableCell className="hidden sm:table-cell">
                  {`${goal.target_value} ${quota.target_metric === "target_number_of_deals" ? "deals" : quota?.currency?.code}`}{" "}
                  / mth
                </TableCell>
                <TableCell className="hidden sm:table-cell">
                  <div className="flex items-center gap-1">
                    <Badge key="months" variant="secondary" className="text-xs">
                      {getFriendlyNameOfMonths(goal.months)}
                    </Badge>
                  </div>
                </TableCell>
                <TableCell className="hidden md:table-cell">
                  <Badge key="months" variant="secondary" className="text-xs">
                    {goal.valid_from || goal.valid_to
                      ? getTimePeriodFromDateRange(
                          {
                            from: goal.valid_from
                              ? new Date(goal.valid_from)
                              : undefined,
                            to: goal.valid_to
                              ? new Date(goal.valid_to)
                              : undefined,
                          },
                          true
                        )
                      : "Forever"}
                  </Badge>
                </TableCell>
                <TableCell className="hidden md:table-cell">
                  <UserAvatarList users={goal.users} />
                </TableCell>
                <TableCell className="text-right"></TableCell>
                <TableCell>
                  <div className="flex items-center gap-3">
                    <Button
                      onClick={() => setLocation(`/edit/${goal.id}`)}
                      variant="default"
                      size="icon"
                      className="bg-[#6B6397]"
                    >
                      <Pencil className="h-4 w-4" />
                    </Button>

                    <ConfirmDeletionDialog
                      key={index}
                      deleteId={goal.id.toString()}
                      deleteOperation={async (id) => {
                        await deleteGoal(Number(id));
                        const updatedGoals = goals.filter(
                          (goal) => goal.id !== Number(id)
                        );
                        mutate({
                          ...quota,
                          goals: updatedGoals,
                        });
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
