import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { ReloadIcon } from "@radix-ui/react-icons";

interface ErrorWithRetryProps {
  error: string;
  onRetry: () => void;
}

export function ErrorWithRetry({ error, onRetry }: ErrorWithRetryProps) {
  return (
    <div className="flex items-center justify-center flex-1 min-h-[60vh]">
      <Card className="w-full max-w-md">
        <CardContent className="pt-6 text-center">
          <div className="mb-6 flex justify-center">
            <div className="rounded-full bg-red-100 p-3">
              <svg
                className="h-6 w-6 text-red-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
          </div>
          <h3 className="mb-2 text-lg font-semibold text-gray-900">
            Failed to Load Data
          </h3>
          <p className="mb-6 text-sm text-gray-500">
            {error || "An error occurred while loading the data. Please try again."}
          </p>
          <Button 
            onClick={onRetry}
            variant="outline"
            className="gap-2"
          >
            <ReloadIcon className="h-4 w-4 animate-spin" />
            Try Again
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}
