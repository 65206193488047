import { createContext, useContext, ReactNode, useState, useEffect } from "react";
import { useCommissionsStream } from "@/hooks/useCommissionsStream";
import { useAppStore } from "@/stores";
import { NestedUser } from "@revelate/types";
import { useLocation } from "wouter";

interface CommissionsContextType {
  users: NestedUser[] | undefined;
  projectedUsers: NestedUser[] | undefined;
  error: string | null;
  isLoading: boolean;
  progress: number;
}

const CommissionsContext = createContext<CommissionsContextType | undefined>(undefined);

export function CommissionsProvider({ children }: { children: ReactNode }) {
  const { currentUser, dateRange } = useAppStore((state) => state);
  const [location] = useLocation();
  const isDashboard = location === "/" || location.startsWith("/dashboard");
  const [usersProgress, setUsersProgress] = useState(0);
  const [projectedProgress, setProjectedProgress] = useState(0);

  // Reset state when switching views
  useEffect(() => {
    // Reset all progress and loading states
    setUsersProgress(0);
    setProjectedProgress(0);
  }, [isDashboard]);

  const {
    data: users,
    isLoading: usersLoading,
    error: usersError,
    progress: usersProgressValue,
    logs: usersLogs,
  } = useCommissionsStream({
    currentUser,
    dateRange,
    isProjected: false,
    enabled: true,
  });

  useEffect(() => {
    setUsersProgress(usersProgressValue);
  }, [usersProgressValue]);

  const {
    data: projectedUsers,
    isLoading: projectedUsersLoading,
    error: projectedError,
    progress: projectedProgressValue,
    logs: projectedLogs,
  } = useCommissionsStream({
    currentUser,
    dateRange,
    isProjected: true,
    enabled: isDashboard,
  });

  useEffect(() => {
    setProjectedProgress(projectedProgressValue);
  }, [projectedProgressValue]);

  // Calculate combined progress based on active streams
  const combinedProgress = isDashboard
    ? Math.min(100, Math.round((usersProgress + projectedProgress) / 2))
    : Math.min(100, usersProgress);

  const value = {
    users,
    projectedUsers: isDashboard ? projectedUsers : undefined,
    error: usersError || (isDashboard ? projectedError : null),
    isLoading: usersLoading || (isDashboard && projectedUsersLoading),
    progress: combinedProgress,
  };

  return (
    <CommissionsContext.Provider value={value}>
      {children}
    </CommissionsContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useCommissions() {
  const context = useContext(CommissionsContext);
  if (context === undefined) {
    throw new Error("useCommissions must be used within a CommissionsProvider");
  }
  return context;
}
