import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
} from "@tanstack/react-table";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "@/components/ui/table";
import { KeyDeal, NestedDealStalled, Scope, TODO } from "@revelate/types";
import { getCurrencyFormatted } from "@/lib/app";
import { getFullName } from "@revelate/utils";
import { useEffect, useState } from "react";
import { getDealOwner } from "@revelate/calc";
import { Badge } from "@/components/ui/badge";

export const keyDealsColumns: ColumnDef<KeyDeal, TODO>[] = [
  {
    id: "owner",
    header: "Owner",
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.original.owner ? getFullName(row.original.owner) : "N/A"}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "dealName",
    header: "Deal",
  },
  {
    header: "Value",
    accessorFn: (row) => getCurrencyFormatted(row.dealValue),
  },
  {
    header: "Commission",
    accessorFn: (row) => getCurrencyFormatted(row.commissionValue),
  },
];

// export const targetKeyDealsColumns: ColumnDef<
//   NestedCommission & { goalsTarget: number },
//   TODO
// >[] = [
//   {
//     id: "owner",
//     header: "Owner",
//     cell: ({ row }) => {
//       return (
//         <div className="flex space-x-2">
//           <span className="max-w-[500px] truncate font-medium">
//             {row.original.user ? getFullName(row.original.user) : "N/A"}
//           </span>
//         </div>
//       );
//     },
//   },
//   {
//     accessorKey: "deal.name",
//     header: "Deal",
//   },
//   {
//     header: "Attainment",
//     accessorFn: (row) =>
//       ((row.deal.value - row.goalsTarget) * 100).toFixed() + "%",
//   },
// ];

export const stalledDealsColumns: ColumnDef<NestedDealStalled, TODO>[] = [
  {
    id: "owner",
    header: "Owner",
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {getDealOwner(row.original)
              ? getFullName(getDealOwner(row.original))
              : "N/A"}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "name",
    header: "Deal",
  },
  {
    header: "Value",
    accessorFn: (row) => getCurrencyFormatted(row.value),
  },
  {
    accessorKey: "comment",
    header: "Comment",
  },
];

interface KeyDealsDataTableProps<TData, TValue> {
  keyDeals: KeyDeal[];
  columns: ColumnDef<TData, TValue>[];
  scope: Scope | null;
}

export function KeyDealsDataTable({
  keyDeals,
  columns,
  scope,
}: KeyDealsDataTableProps<KeyDeal, TODO>) {
  const [columnVisibility, setColumnVisibility] = useState({
    owner: true,
  });

  useEffect(() => {
    if (scope?.type === "user") {
      setColumnVisibility({ owner: false });
    } else {
      setColumnVisibility({ owner: true });
    }
  }, [scope]);

  const table = useReactTable({
    data: keyDeals,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: { columnVisibility },
  });

  return (
    <div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead className="px-0 pr-5" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell className="px-0 pr-5" key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

interface NestedDealsMiniDataTableProps<TData, TValue> {
  deals: NestedDealStalled[];
  columns: ColumnDef<TData, TValue>[];
  scope: Scope | null;
}

export function NestedDealsMiniDataTable({
  deals,
  columns,
  scope,
}: NestedDealsMiniDataTableProps<NestedDealStalled, TODO>) {
  const [columnVisibility, setColumnVisibility] = useState({
    owner: true,
  });

  useEffect(() => {
    if (scope?.type === "user") {
      setColumnVisibility({ owner: false });
    } else {
      setColumnVisibility({ owner: true });
    }
  }, [scope]);

  const table = useReactTable({
    data: deals,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: { columnVisibility },
  });

  return (
    <div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead className="px-0 pr-5" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell, index) => (
                <TableCell className="px-0 pr-5" key={cell.id}>
                  {cell.column.id === "comment" && index === 2 ? (
                    <Badge variant="unapproved">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Badge>
                  ) : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
