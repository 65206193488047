import {
  AppRole,
  DomainType,
  ListItem,
  Scope,
  ScopeType,
  TODO,
  SignedInUser,
  SignedInCompany,
} from "@revelate/types";
import { getCompanyInitials } from "./companies";
import { getTeamInitials } from "./teams";
import { getFullName, getUserInitials, sortByStringKey } from "@revelate/utils";

export const getList = (
  company: SignedInCompany | null,
  currentUser: SignedInUser | null
) => {
  if (!company || !currentUser) return [];
  const list: ListItem[] = [];

  if (currentUser?.role === "super_admin" || currentUser?.role === "admin") {
    // Add company
    list.push({
      label: "Companies",
      type: "company",
      scopes: [
        {
          type: "company",
          label: company?.name,
          value: String(company.id),
          description: "Company",
          avatar: company?.avatar_url || "",
          avatar_fallback: getCompanyInitials(company),
        },
      ],
    });
  }

  const users: Scope[] = [];
  // Add users
  company?.users
    ?.filter(
      (u) =>
        currentUser?.role !== "employee" ||
        (currentUser?.role === "employee" && currentUser.id === u.id)
    )
    .sort(sortByStringKey("first_name"))
    .map((user) => {
      users.push({
        type: "user",
        label: getFullName(user as TODO),
        value: user.id,
        description: user.email,
        avatar: user.avatar_url || "",
        avatar_fallback: getUserInitials(user),
      });
    });
  if (company?.users.length > 0)
    list.push({
      label: "Users",
      type: "user",
      scopes: users,
    });
  if (currentUser?.role !== "employee") {
    // Add Teams
    const teams: Scope[] = [];
    company?.teams.sort(sortByStringKey("name")).map((team) => {
      teams.push({
        type: "team",
        label: team?.name,
        value: String(team.id),
        description: "Team",
        avatar: "",
        avatar_fallback: getTeamInitials(team),
      });
    });
    if (company?.teams.length > 0)
      list.push({
        label: "Teams",
        type: "team",
        scopes: teams,
      });
  }
  if (currentUser?.role === "super_admin" || currentUser?.role === "admin") {
    // Add Plans
    const plans: Scope[] = [];
    company?.plans.sort(sortByStringKey("name")).map((team) => {
      plans.push({
        type: "plan",
        label:
          team?.name.length > 30
            ? team?.name.substring(0, 30) + "..."
            : team?.name,
        value: String(team.id),
        description: "Compensation Plan",
        avatar: company?.avatar_url || "",
        avatar_fallback: getCompanyInitials(company),
      });
    });
    if (company?.plans.length > 0)
      list.push({
        label: "Compensation Plans",
        type: "plan",
        scopes: plans,
      });
    // Add Quotas
    const quotas: Scope[] = [];
    company?.quotas.sort(sortByStringKey("name")).map((quota) => {
      quotas.push({
        type: "quota",
        label:
          quota?.name.length > 30
            ? quota?.name.substring(0, 30) + "..."
            : quota?.name,
        value: String(quota.id),
        description: "Quotas",
        avatar: company?.avatar_url || "",
        avatar_fallback: getCompanyInitials(company),
      });
    });
    if (company?.quotas.length > 0)
      list.push({
        label: "Quotas",
        type: "quota",
        scopes: quotas,
      });
    // // Add currencies
    // const currencies: Scope[] = [];
    // company?.currencies.sort(sortByStringKey("code")).map((currency) => {
    //   currencies.push({
    //     type: "currency",
    //     label: currency.code,
    //     value: String(currency.id),
    //     description: "Currencies",
    //     avatar: company?.avatar_url || "",
    //     avatar_fallback: getCompanyInitials(company),
    //   });
    // });
    // if (company?.currencies.length > 0)
    //   list.push({
    //     label: "Currencies",
    //     type: "currency",
    //     scopes: currencies,
    //   });
  }
  return list;
};

export const scopeTypeAvailableForDomain = (
  type: ScopeType,
  domain?: DomainType
) => {
  switch (domain) {
    case "dashboard":
      return ["company", "user", "team"].includes(type);
    case "plans":
      return ["plan"].includes(type);
    case "quotas":
      return ["quota"].includes(type);
    case "users":
      return ["user"].includes(type);
    case "reports":
      return ["company", "user", "team"].includes(type);
    // case "currencies":
    //   return ["currency"].includes(type);
    default:
      return false;
  }
};

export const getScope = (
  list?: ListItem[] | null,
  type?: ScopeType,
  id?: string,
  userRole?: AppRole | null
) => {
  if (!list || list.length === 0 || !id) return null;
  if (!type || !id) return getDefaultScope(list, type, id, userRole);
  const item = list.find((item: TODO) => item.type === type);
  if (!item) return null;
  const scope = item.scopes.find((scope: TODO) => scope.value === id);
  if (!scope) return null;
  return scope;
};

export const getDefaultScope = (
  list: ListItem[],
  type?: ScopeType,
  id?: string,
  userRole?: AppRole | null
) => {
  const defaultType =
    userRole === "employee"
      ? "user"
      : userRole === "manager"
        ? "team"
        : "company";
  const listItem = list.find((item) => item.type === (type || defaultType));
  if (!listItem) return list[0].scopes[0];
  // if (!id) return listItem.scopes[0];
  const scope = listItem.scopes.find((scope) => scope.value === id);
  if (!scope) return listItem.scopes[0];
  return scope;
};
