import { SignedInCompany, SignedInUser } from "@revelate/types";
import { supabaseClient } from "./client";
import { getTableColums } from "./shared";

export const getCompanies = async (user: SignedInUser) => {
  if (!user) return [];
  const { data, error } = await supabaseClient
    .from("companies")
    .select("name, id")
    .order("id");
  // .returns<Database["public"]["Tables"]["companies"]["Row"][]>();
  if (error) {
    throw error;
  }
  return data;
};

export const updateCompanyCurrency = async (
  companyId: number,
  currencyId: number
) => {
  const { data, error } = await supabaseClient
    .from("companies")
    .update({
      default_currency_id: currencyId,
    })
    .eq("id", companyId)
    .select(getTableColums("companies"))
    .returns<SignedInCompany[]>()
    .maybeSingle();
  if (error) {
    throw error;
  }
  return data;
};
