import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "@/components/ui/table";
import { NestedCurrency, NestedGoal, NestedQuota, TODO } from "@revelate/types";
import {
  getCalculationPeriodLabelFromValue,
  getCommissionDealTypeLabels,
  getNumberFormatted,
  getProviderLabel,
  getQuotaUnitFromValue,
} from "@/lib/app";
import { Badge } from "@/components/ui/badge";
import {
  capitalizeFirstLetter,
  getFullName,
  getUserInitials,
} from "@revelate/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { getPercentageNumeric } from "@revelate/calc";
import { Button } from "@/components/ui/button";
import { ConfirmDeletionDialog } from "../edit/confirm-deletion";
import { Pencil } from "lucide-react";
import { useState } from "react";
import { DataTableColumnHeader } from "@/deals/components/data-table-column-header";
import { UserAvatarList } from "@/components/UserAvatarList";

const getQuotaGoalsRange = (goals: NestedGoal[]): [number, number?] | null => {
  if (!goals || goals.length === 0) return null;

  let lowestRate = goals[0].target_value;
  let highestRate = goals[0].target_value;

  goals.forEach((goal) => {
    if (goal.target_value < lowestRate) {
      lowestRate = goal.target_value;
    }
    if (goal.target_value > highestRate) {
      highestRate = goal.target_value;
    }
  });

  if (lowestRate === highestRate) {
    return [getPercentageNumeric(lowestRate)];
  }

  return [getPercentageNumeric(lowestRate), getPercentageNumeric(highestRate)];

  // return `${getPercentage(lowestRate)} - ${getPercentage(highestRate)}`;
};

function stringifyQuotaGoalsRange(range: [number, number?] | null) {
  if (range === null) return "";

  if (range.length === 1) return getNumberFormatted(range[0]);
  if (range.length === 2 && typeof range[1] === "number")
    return `${getNumberFormatted(range[0])} - ${getNumberFormatted(range[1])}`;

  return "";
}

interface CreateQuotasTableColumnsProps {
  goToQuota: (quotaId: string) => void;
  onDeleteQuota: (quotaId: string) => Promise<void>;
}

export function createQuotasTableColumns({
  goToQuota,
  onDeleteQuota,
}: CreateQuotasTableColumnsProps): ColumnDef<NestedQuota, TODO>[] {
  return [
    {
      accessorKey: "name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      cell: ({ row }) => {
        return (
          <>
            <div className="font-medium">{row?.original?.name}</div>
            <div className="hidden text-sm text-muted-foreground md:inline">
              {getCalculationPeriodLabelFromValue(
                row?.original?.calculation_period || "Month"
              )}{" "}
              quota for {getCommissionDealTypeLabels(row?.original?.deal_types)}
            </div>
          </>
        );
      },
      enableSorting: true,
      sortingFn: (rowA, rowB) =>
        rowA?.original?.name?.localeCompare(rowB?.original?.name),
      enableHiding: false,
    },
    {
      id: "goals",
      accessorKey: "goals",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Target" />
      ),
      cell: ({ row }) => {
        return (
          <>
            <div className="font-medium">
              {" "}
              {row?.original?.goals?.length > 0 ? (
                `${stringifyQuotaGoalsRange(
                  getQuotaGoalsRange(row?.original?.goals)
                )} ${row?.original?.target_metric === "target_number_of_deals" ? "deals" : row?.original?.currency?.code} per mth`
              ) : (
                <Badge
                  key={row?.index}
                  className="text-xs"
                  variant={
                    row?.original?.goals?.length > 0 ? "paid" : "destructive"
                  }
                >
                  Needs action
                </Badge>
              )}
            </div>
            <div className="hidden text-sm text-muted-foreground md:inline">
              {row?.original?.goals?.length > 0
                ? row?.original?.goals?.length
                : "No"}{" "}
              sales goals
            </div>
          </>
        );
      },
      // enableSorting: true,
      // sortingFn: (rowA, rowB) => {
      //   const rowARange = getPlanAcceleratorsRange(
      //     rowA?.original?.accelerators
      //   );
      //   const rowBRange = getPlanAcceleratorsRange(
      //     rowB?.original?.accelerators
      //   );

      //   if (rowARange === null) return 1;
      //   if (rowBRange === null) return -1;

      //   return rowARange[0] - rowBRange[0];
      // },
      enableHiding: false,
    },
    // {
    //   id: "assigned-to",
    //   header: "Assigned to (Future)",
    //   cell: ({ row }) => {
    //     return (
    //       <div className="flex items-center gap-1">
    //         <UserAvatarList users={row?.original?.users} />
    //       </div>
    //     );
    //   },
    // },
    {
      id: "providers",
      header: "Deal Providers",
      cell: ({ row }) => {
        return row?.original?.providers ? (
          <div className="gap-1 flex">
            {row?.original?.providers?.map((provider) => (
              <Badge key={row?.index} className="text-xs" variant="secondary">
                {getProviderLabel(provider)}
              </Badge>
            ))}
          </div>
        ) : (
          <Badge className="text-xs" variant="secondary">
            Any
          </Badge>
        );
      },
    },
    {
      id: "status",
      accessorKey: "status",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      cell: ({ row }) => {
        return (
          <Badge
            className="text-xs"
            variant={
              row?.original?.status === "published"
                ? "paid"
                : row?.original?.status === "draft"
                  ? "approved"
                  : "secondary"
            }
          >
            {capitalizeFirstLetter(row?.original?.status)}
          </Badge>
        );
      },
      enableSorting: true,
      sortingFn: (rowA, rowB) =>
        rowA?.original?.status?.localeCompare(rowB?.original?.status),
      enableHiding: false,
    },
    {
      id: "actions",
      header: "",
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-3">
            <Button
              onClick={() => goToQuota(row?.original?.id?.toString())}
              variant="default"
              size="icon"
              className="bg-[#6B6397]"
            >
              <Pencil className="h-4 w-4" />
            </Button>

            <ConfirmDeletionDialog
              key={row?.index}
              deleteId={row?.original?.id.toString()}
              deleteOperation={() =>
                onDeleteQuota(row?.original?.id?.toString())
              }
            />
          </div>
        );
      },
    },
  ];
}

interface QuotasTableProps<TData, TValue> {
  quotas: NestedQuota[];
  columns: ColumnDef<TData, TValue>[];
}

export function QuotasTable({ quotas, columns }: QuotasTableProps<TODO, TODO>) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data: quotas,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    enableSorting: true,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
