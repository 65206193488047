// import Image from "next/image";
// import Link from "next/link";
import { Pencil, Plus } from "lucide-react";

import { Badge } from "@/components/ui/badge";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useAppStore } from "@/stores";
import { useLocation } from "wouter";
import useSWR from "swr";

import { getCommissionDealTypeLabels } from "@/lib/app";
import { TableType, deletePlan, getAll } from "@/lib/supabase";
import { Loader } from "@/components/Loader";
import { LoadingError } from "@/components/LoadingError";
import { ConfirmDeletionDialog } from "./edit/confirm-deletion";
import { getRoute } from "@/lib/routes";
import {
  capitalizeFirstLetter,
  getFullName,
  getUserInitials,
} from "@revelate/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { AcceleratorWithConditions, NestedUser } from "@revelate/types";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { getPercentage } from "@revelate/calc";
import { createPlansTableColumns, PlansTable } from "./components/plans-table";
import { ErrorWithRetry } from "@/components/ErrorWithRetry";

const getPlanAcceleratorsRange = (
  accelerators: AcceleratorWithConditions[]
) => {
  if (!accelerators || accelerators.length === 0) return "";

  let lowestRate = accelerators[0].rate;
  let highestRate = accelerators[0].rate;

  accelerators.forEach((accelerator) => {
    if (accelerator.rate < lowestRate) {
      lowestRate = accelerator.rate;
    }
    if (accelerator.rate > highestRate) {
      highestRate = accelerator.rate;
    }
  });

  if (lowestRate === highestRate) {
    return getPercentage(lowestRate);
  }

  return `${getPercentage(lowestRate)} - ${getPercentage(highestRate)}`;
};

export function PlansPage() {
  const appState = useAppStore((state) => state);
  const { currentUser } = appState;

  const {
    data: plans,
    error,
    isLoading,
    mutate,
  } = useSWR(
    [
      "plans" as TableType,
      currentUser ? currentUser?.company_id.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const [, setLocation] = useLocation();

  const plansTableColumns = createPlansTableColumns({
    goToPlan: (id: string) => setLocation(getRoute("plans", "plan", id)),
    onDeletePlan: async (id: string) => {
      await deletePlan(Number(id));
      const updatedPlans = plans?.filter((plan) => plan.id !== Number(id));
      mutate(updatedPlans);
    },
  });

  if (isLoading || !plans) {
    return <Loader />;
  }
  if (error) {
    return <ErrorWithRetry error={error.message} onRetry={() => window.location.reload()} />;
  }

  return (
    <main className="grid flex-1 items-start gap-4  sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-3">
        <Card x-chunk="dashboard-05-chunk-3">
          <div className="flex">
            <CardHeader className="flex px-7">
              <CardTitle>Compensation Plans</CardTitle>
              <CardDescription>
                Set a reward structure for your sales team.
              </CardDescription>
            </CardHeader>
            <div className="ml-auto flex items-center gap-2 pr-7">
              <Button
                onClick={() => setLocation(getRoute("plans", "plan", "new"))}
                className="bg-[#6B6397]"
              >
                <Plus className="mr-2 h-4 w-4" />
                Add new plan
              </Button>
            </div>
          </div>
          <CardContent>
            {plans && plans.length > 0 ? (
              <PlansTable plans={plans} columns={plansTableColumns} />
            ) : (
              <p className="text-lg text-muted-foreground text-center">
                Add your first plan to start rewarding performance 🚀
              </p>
            )}
          </CardContent>
        </Card>
      </div>
    </main>
  );
}
