import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { NestedDeal, SignedInCompany } from "@revelate/types";
import { CreditCard, Handshake, Scale } from "lucide-react";
import { getCurrencyFormatted } from "@/lib/app";
import { getAverageDealValue, getDealsValue } from "@revelate/calc";

interface DealKeyValueProps {
  company: SignedInCompany;
  scopedDeals: NestedDeal[];
}

export function DealKeyValues({ company, scopedDeals }: DealKeyValueProps) {
  const scopedDealsWon = scopedDeals.filter((deal) => deal.is_won);

  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Deals won</CardTitle>
          <Handshake className="h-4 w-4" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {scopedDealsWon?.length || 0}
          </div>
          {/* <p className="text-xs text-muted-foreground">
                      {getDifferenceToPreviousPeriod(
                        deals?.length || 0,
                        previousDeals?.length || 0
                      )}
                    </p> */}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Won deal value</CardTitle>
          <CreditCard className="h-4 w-4" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {getCurrencyFormatted(getDealsValue(company, scopedDealsWon || []))}
          </div>
          {/* <p className="text-xs text-muted-foreground">
                      {getDifferenceToPreviousPeriod(
                        getDealsValue(deals || []),
                        getDealsValue(previousDeals || [])
                      )}
                    </p> */}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">
            Average deal value
          </CardTitle>
          <Scale className="h-4 w-4" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {getCurrencyFormatted(
              getAverageDealValue(company, scopedDeals || [])
            )}
          </div>
          {/* <p className="text-xs text-muted-foreground">
                      {getDifferenceToPreviousPeriod(
                        getDealsValue(deals || []),
                        getDealsValue(previousDeals || [])
                      )}
                    </p> */}
        </CardContent>
      </Card>
      {/* <Card>
                  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                      Active Now
                    </CardTitle>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="h-4 w-4 text-muted-foreground"
                    >
                      <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                    </svg>
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">+573</div>
                    <p className="text-xs text-muted-foreground">
                      +201 since last hour
                    </p>
                  </CardContent>
                </Card> */}
    </div>
  );
}
