import { useEffect, useState, useRef } from "react";
import { DateRange } from "react-day-picker";
import { SignedInUser, NestedUser } from "@revelate/types";
import { getCalcUrl } from "@/lib/api";
import useSWR, { KeyedMutator } from "swr";

interface UseCommissionsStreamProps {
  currentUser: SignedInUser | null;
  dateRange?: DateRange;
  users?: NestedUser[];
  isProjected?: boolean;
  logResult?: boolean;
  enabled?: boolean;
}

interface CommissionsStreamState {
  data: NestedUser[];
  error: string | null;
  isLoading: boolean;
  logs?: string[];
  progress: number;
}

const initialState: CommissionsStreamState = {
  data: [],
  error: null,
  isLoading: false,
  progress: 0,
};

export function useCommissionsStream({
  currentUser,
  dateRange,
  users,
  isProjected = false,
  logResult = false,
  enabled = true,
}: UseCommissionsStreamProps) {
  const [streamState, setStreamState] =
    useState<CommissionsStreamState>(initialState);
  const [lastUrl, setLastUrl] = useState<string | null>(null);
  const progressIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const url = enabled ? getCalcUrl(currentUser, dateRange, users, isProjected, logResult) : null;

  // Use SWR for caching the latest complete result
  const { data: cachedData, mutate } = useSWR(
    url,
    null, // We don't use the default fetcher
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: false,
      revalidateIfStale: false,
      dedupingInterval: 30000,
      refreshInterval: 0,
    }
  ) as {
    data: NestedUser[];
    mutate: KeyedMutator<NestedUser[]>;
  };

  useEffect(() => {
    // If disabled, reset state and return early
    if (!enabled) {
      setStreamState(initialState);
      return;
    }

    // If we have cached data and no critical params changed, use cache
    if (cachedData && !logResult && url === lastUrl) {
      setStreamState({
        data: cachedData,
        error: null,
        isLoading: false,
        progress: 100,
      });
      return;
    }

    if (!url) {
      setStreamState((prev) => ({
        ...prev,
        error: "Invalid URL parameters",
        isLoading: false,
        progress: 0,
      }));
      return;
    }

    setLastUrl(url);
    let isSubscribed = true;

    // Abort any existing request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create new abort controller
    const controller = new AbortController();
    abortControllerRef.current = controller;

    async function fetchStream() {
      if (!isSubscribed) return;
      
      // Clear any existing progress interval
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }

      // Only reset progress if it's a new request or there was an error
      setStreamState((prev) => ({ 
        ...prev, 
        isLoading: true, 
        error: null,
        // Keep the existing progress if it's high enough, otherwise start from 0
        progress: prev.progress && prev.progress > 90 ? prev.progress : 0
      }));

      // Start progress simulation immediately
      let simulatedProgress = Math.min(95, streamState.progress || 0);
      progressIntervalRef.current = setInterval(() => {
        if (simulatedProgress >= 95) {
          // If we're already at 95%, don't update anymore
          clearInterval(progressIntervalRef.current!);
          return;
        }
        simulatedProgress = Math.min(95, simulatedProgress + (Math.random() * 2 + 1) * (1 - simulatedProgress/100));
        setStreamState(prev => ({
          ...prev,
          progress: Math.min(100, Math.round(simulatedProgress))
        }));
      }, 200);

      let response: Response | undefined;
      let reader: ReadableStreamDefaultReader | undefined;

      try {
        response = await fetch(`${import.meta.env.VITE_API_URL}/${url}`, {
          signal: controller.signal,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + import.meta.env.VITE_API_KEY,
          },
        });

        if (!response.ok) throw new Error(response.statusText);
        if (!response.body) throw new Error("No response body");

        reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = "";
        let isReading = true;
        let lastProgressTime = Date.now();
        let totalBytes = 0;
        let progressPhase = 0;
        const PROGRESS_UPDATE_INTERVAL = 500; // Update more frequently
        const PHASE_THRESHOLDS = [15, 35, 65, 85]; // Progress phases for natural progression
        const getProgressForPhase = (bytes: number, phase: number) => {
          const baseProgress = Math.min(PHASE_THRESHOLDS[phase], (bytes / (50 * 1024)) * (PHASE_THRESHOLDS[phase] / 2));
          // Add slight randomization for more natural feel
          const jitter = Math.random() * 2 - 1; // -1 to 1
          return Math.min(PHASE_THRESHOLDS[phase], Math.max(0, baseProgress + jitter));
        };

        // Start with cached data if available
        if (cachedData && isSubscribed) {
          setStreamState((prev) => ({
            ...prev,
            data: cachedData,
            isLoading: true,
            progress: 0,
          }));
        }

        while (isReading && isSubscribed && !controller.signal.aborted) {
          const { done, value } = await reader.read();

          if (done) {
            isReading = false;
            if (buffer) {
              try {
                const result = JSON.parse(buffer);
                const newState = {
                  data: result.data || [],
                  logs: result.logs,
                  isLoading: false,
                  error: null,
                  progress: 100,
                };
                if (isSubscribed && !controller.signal.aborted) {
                  // Clear the progress interval
                  if (progressIntervalRef.current) {
                    clearInterval(progressIntervalRef.current);
                  }
                  // Quick transition to 100%
                  setStreamState(prev => ({
                    ...prev,
                    progress: Math.min(100, 99) // Show near-completion immediately
                  }));
                  // Minimal delay before showing completion
                  setTimeout(() => {
                    if (isSubscribed && !controller.signal.aborted) {
                      setStreamState(newState);
                      mutate(result.data, false);
                    }
                  }, 100);
                }
              } catch (e) {
                console.error("Error parsing final buffer:", e);
              }
            }
            break;
          }

          buffer += decoder.decode(value, { stream: true });
          totalBytes += value.length;
          
          // Update progress with natural progression
          const now = Date.now();
          if (now - lastProgressTime > PROGRESS_UPDATE_INTERVAL) {
            // Progress through phases based on accumulated data
            if (totalBytes > (progressPhase + 1) * 50 * 1024 && progressPhase < PHASE_THRESHOLDS.length - 1) {
              progressPhase++;
            }
            
            const currentProgress = getProgressForPhase(totalBytes, progressPhase);
            
            if (isSubscribed && !controller.signal.aborted) {
              setStreamState(prev => ({
                ...prev,
                progress: Math.round(currentProgress)
              }));
            }
            lastProgressTime = now;
          }

          // Try to parse complete JSON objects as they arrive
          try {
            const result = JSON.parse(buffer);
            if (result.data) {
              const newState = {
                data: result.data,
                logs: result.logs,
                isLoading: false,
                error: null,
                progress: 100,
              };
              if (isSubscribed && !controller.signal.aborted) {
                // Clear the progress interval
                if (progressIntervalRef.current) {
                  clearInterval(progressIntervalRef.current);
                }
                // Quick transition to 100%
                setStreamState(prev => ({
                  ...prev,
                  progress: Math.min(100, 99) // Show near-completion immediately
                }));
                // Minimal delay before showing completion
                setTimeout(() => {
                  if (isSubscribed && !controller.signal.aborted) {
                    setStreamState(newState);
                    mutate(result.data, false);
                  }
                }, 100);
              }
              buffer = "";
              isReading = false;
              break;
            }
          } catch (e) {
            continue;
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          // Don't set error state for aborted requests
          if (error.name === "AbortError" || !isSubscribed) {
            return;
          }
          setStreamState((prev) => ({
            ...prev,
            error: error.message,
            isLoading: false,
            progress: 0,
          }));
        }
      } finally {
        // Clean up the reader if it exists
        if (response?.body?.locked) {
          await reader?.cancel();
        }
        // Clear the abort controller reference if this is still the current one
        if (abortControllerRef.current === controller) {
          abortControllerRef.current = null;
        }
      }
    }

    fetchStream();

    return () => {
      isSubscribed = false;
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  }, [url, cachedData, enabled, logResult, mutate]);

  return streamState;
}
