import { getColumns } from "./components/columns";
import { DataTable } from "./components/data-table";

import { TableType, getAll } from "@/lib/supabase";
import useSWR from "swr";
import { useAppStore } from "@/stores";
import { Loader } from "@/components/Loader";
import { LoadingError } from "@/components/LoadingError";
import { useAPI } from "@/hooks/useAPI";
import { useState } from "react";
import { UserWithDeals } from "@revelate/types";
import { ErrorWithRetry } from "@/components/ErrorWithRetry";

export const DealsPage = () => {
  const currentUser = useAppStore((state) => state.currentUser);
  const {
    data: deals,
    error,
    isLoading,
    mutate,
  } = useSWR(
    [
      "deals" as TableType,
      currentUser ? currentUser?.company_id.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const {
    data: users,
    error: usersError,
    isLoading: usersIsLoading,
  } = useSWR(
    [
      "users" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
      false,
    ],
    ([table, companyId, activeOnly]): Promise<UserWithDeals[]> =>
      getAll(table, companyId, activeOnly),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const {
    data: tokens,
    error: tokensError,
    isLoading: tokensIsLoading,
  } = useSWR(
    [
      "tokens" as TableType,
      currentUser ? currentUser?.company_id.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const { syncDealsByProvider, isLoading: isAPILoading } = useAPI();
  const [clickedRow, setClickedRow] = useState<string | null>(null);

  if (
    isLoading ||
    !deals ||
    !currentUser ||
    usersIsLoading ||
    tokensIsLoading
  ) {
    return <Loader />;
  }

  if (error || usersError || tokensError) {
    return <ErrorWithRetry error={error.message || usersError.message || tokensError.message} onRetry={() => window.location.reload()} />;
  }

  return (
    <>
      <div className="flex items-center justify-between space-y-2">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">Deals</h2>
          <p className="text-muted-foreground">
            Verify data and manually sync deals with your CRM here.
          </p>
        </div>
      </div>
      <DataTable
        data={deals}
        columns={getColumns({
          syncDealsByProvider,
          isAPILoading,
          clickedRow,
          setClickedRow,
          mutate,
          users,
          tokens,
        })}
      />
    </>
  );
};
