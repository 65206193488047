import { useLocation, useParams } from "wouter";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { capitalizeFirstLetter } from "@revelate/utils";
import { useForm } from "react-hook-form";
import { Database, NestedCurrency, NestedQuota } from "@revelate/types";
import type { KeyedMutator } from "swr";

import { getQuotaUnitFromValue } from "@/lib/app";
import DatePickerWithRange from "./components/date-picker-with-range";
import { MonthPicker } from "./components/month-picker";
import { upsertGoal } from "@/lib/supabase/goals";
import EmployeesPage from "./employees/page";

const profileFormSchema = z.object({
  target_value: z.number({
    required_error: "Please enter a target value.",
  }),
  months: z.array(z.number()).refine((value) => value.some((item) => item), {
    message: "You have to select at least one item.",
  }),
  valid_from_to_range: z.object({
    from: z.date().optional(),
    to: z.date().optional(),
  }),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

export const GoalsForm = ({
  quota,
  currencies,
  mutate,
}: {
  quota: NestedQuota;
  currencies?: NestedCurrency[];
  mutate: KeyedMutator<NestedQuota | null>;
}) => {
  const [, setLocation] = useLocation();
  const { goals } = quota || {};
  const { goalId } = useParams();
  const editGoal = goals?.find((g) => g.id === Number(goalId));

  async function onSubmit(data: ProfileFormValues) {
    if (!quota) return;
    const goalData: Database["public"]["Tables"]["goals"]["Insert"] = {
      id: editGoal?.id,
      quota_id: quota?.id,
      target_value: data.target_value,
      months: data.months,
      valid_from: data.valid_from_to_range.from
        ? data.valid_from_to_range.from.toISOString()
        : null,
      valid_to: data.valid_from_to_range.to
        ? data.valid_from_to_range.to.toISOString()
        : null,
    };
    const goal = await upsertGoal(goalData);
    if (!goal) return;
    mutate({
      ...quota,
      goals: quota?.goals.map((g) => (goal && g.id === goal.id ? goal : g)),
    });
    setLocation("/");
  }

  // // This can come from your database or API.
  const defaultValues: Partial<ProfileFormValues> = {
    target_value: editGoal?.target_value || undefined,
    months: editGoal?.months || [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    valid_from_to_range: {
      from: editGoal?.valid_from ? new Date(editGoal?.valid_from) : undefined,
      to: editGoal?.valid_to ? new Date(editGoal?.valid_to) : undefined,
    },
  };

  const currency = currencies?.find((c) => c.id === quota?.currency_id);

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: "onChange",
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="target_value"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Monthly target value</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  // type="number"
                  onChange={(event) =>
                    field.onChange(Number(event.target.value))
                  }
                  placeholder={`${
                    quota?.target_metric === "target_number_of_deals"
                      ? "Deals per month"
                      : `${currency?.code} per month`
                  }`}
                />
              </FormControl>
              <FormDescription className="text-xs">
                Set the target value in{" "}
                {`${
                  quota?.target_metric === "target_number_of_deals"
                    ? "deals per month"
                    : `${currency?.code} per month`
                }`}
                . If you have a quarterly or yearly target, divide it
                accordingly.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="months"
          render={({ field: { value, onChange } }) => (
            <FormItem>
              <FormLabel>Time period</FormLabel>
              <FormControl>
                <MonthPicker months={value} setMonths={onChange} />
              </FormControl>
              <FormDescription className="text-xs">
                Select calendar months in which the goal is valid.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="valid_from_to_range"
          render={({ field: { value, onChange } }) => (
            <FormItem>
              <FormLabel>From/to dates</FormLabel>
              <FormControl>
                <DatePickerWithRange
                  date={value}
                  setDate={onChange}
                  className="[&>button]:w-[260px]"
                />
              </FormControl>
              <FormDescription className="text-xs">
                Optionally limit the from/to date(s) to set a fixed validity
                period for the goal.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <EmployeesPage quota={quota} mutate={mutate} />

        <Button type="submit" className="bg-[#6B6397]">
          Continue
        </Button>
      </form>
    </Form>
  );
};
