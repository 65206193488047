import { Plus } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

import { useAppStore } from "@/stores";
import { useLocation } from "wouter";
import useSWR from "swr";

import { TableType, deleteTeam, getAll } from "@/lib/supabase";
import { Loader } from "@/components/Loader";
import { LoadingError } from "@/components/LoadingError";
import { getRoute } from "@/lib/routes";

import { createTeamsTableColumns, TeamsTable } from "./components/teams-table";
import { ErrorWithRetry } from "@/components/ErrorWithRetry";

export function TeamsPage() {
  const appState = useAppStore((state) => state);
  const { currentUser } = appState;

  const {
    data: teams,
    mutate,
    error,
    isLoading,
  } = useSWR(
    [
      "teams" as TableType,
      currentUser ? currentUser?.company_id.toString() : null,
    ],
    ([table, companyId]) => getAll(table, companyId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const [, setLocation] = useLocation();

  const teamsTableColumns = createTeamsTableColumns({
    goToTeam: (id: string) => setLocation(getRoute("teams", "team", id)),
    onDeleteTeam: async (id: string) => {
      await deleteTeam(Number(id));
      const updatedTeams = teams?.filter((team) => team.id !== Number(id));
      mutate(updatedTeams);
    },
  });

  if (isLoading || !teams) {
    return <Loader />;
  }
  if (error) {
    return <ErrorWithRetry error={error.message} onRetry={() => window.location.reload()} />;
  }

  return (
    <main className="grid flex-1 items-start gap-4  sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-3">
        <Card x-chunk="dashboard-05-chunk-3">
          <div className="flex">
            <CardHeader className="flex px-7">
              <CardTitle>Teams</CardTitle>
              <CardDescription>
                Group your employees to track team performance.
              </CardDescription>
            </CardHeader>
            <div className="ml-auto flex items-center gap-2 pr-7">
              <Button
                onClick={() => setLocation(getRoute("teams", "team", "new"))}
                className="bg-[#6B6397]"
              >
                <Plus className="mr-2 h-4 w-4" />
                Add new team
              </Button>
            </div>
          </div>
          <CardContent>
            {teams && teams.length > 0 ? (
              <TeamsTable teams={teams} columns={teamsTableColumns} />
            ) : (
              <p className="text-lg text-muted-foreground text-center">
                Add your first team to start rewarding performance 🚀
              </p>
            )}
          </CardContent>
        </Card>
      </div>
    </main>
  );
}
