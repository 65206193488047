import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { isDefaultCurrency } from "@/lib/app";
import { zodResolver } from "@hookform/resolvers/zod";
import { Pencil } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { KeyedMutator } from "swr";
import { useState } from "react";
import { upsertExchangeRate } from "@/lib/supabase/exchange_rates";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { ToastAction } from "@radix-ui/react-toast";
import { postAPI } from "@/lib/api";

const profileFormSchema = z.object({
  name: z.string({
    required_error: "Please enter a name.",
  }),
  email: z.string({
    required_error: "Please enter a email.",
  }),
  role: z.string({
    required_error: "Please enter a role.",
  }),
  companyId: z.string(),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

type BasicCompany = {
  name: string;
  id: number;
};

export function NewUserForm({
  open,
  setOpen,
  companies,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  companies?: BasicCompany[];
}) {
  const { toast } = useToast();
  const defaultValues: Partial<ProfileFormValues> = {
    name: "",
    email: "",
    role: "",
    companyId: undefined,
  };

  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: "onChange",
  });

  const roles = [
    { value: "admin", label: "Admin" },
    { value: "manager", label: "Manager" },
    { value: "employee", label: "Employee" },
  ];

  async function onSubmit(data: ProfileFormValues) {
    const result = await postAPI(
      "/users",
      JSON.stringify({
        companyId: data.companyId,
        name: data.name,
        email: data.email,
        role: data.role,
      })
    );

    if (result) {
      setOpen(false);
      toast({
        title: "Successfully added user",
        variant: "default",
        description:
          "The user may now login to the dashboard using their email address.",
        duration: 20000,
      });
    } else {
      toast({
        title: "Failed to add user",
        variant: "destructive",
        description:
          "An error occurred while adding the user. Please try again.",
        // action: (
        //   <ToastAction onClick={handleClick} altText="Try again">
        //     Try again
        //   </ToastAction>
        // ),
        // duration: 20000,
      });
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <DialogHeader>
              <DialogTitle>Add new user</DialogTitle>
              <DialogDescription>
                Quickly add a new user to a company.
              </DialogDescription>
            </DialogHeader>

            <div className="grid gap-4 py-4">
              <FormField
                control={form.control}
                name="companyId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Company</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Pick a company" />
                        </SelectTrigger>
                        <SelectContent>
                          {companies?.map((company, index) => (
                            <SelectItem
                              key={index}
                              value={company.id.toString()}
                            >
                              {company.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormDescription className="text-xs">
                      Select the company to add the user to.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Elon Dusk" />
                    </FormControl>
                    <FormDescription className="text-xs">
                      Full name of the user.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="role"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Role</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Pick a role" />
                        </SelectTrigger>
                        <SelectContent>
                          {roles?.map((role, index) => (
                            <SelectItem key={index} value={role.value}>
                              {role.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormDescription className="text-xs">
                      Access privileges of the user.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="elon@spacex.com" />
                    </FormControl>
                    <FormDescription className="text-xs">
                      Email address of the user.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <Button type="submit">Continue</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
