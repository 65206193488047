import {
  CommissionMonth,
  CommissionMonthWithProjected,
  CommissionStatus,
  GroupedCommissions,
  NestedCommission,
  TODO,
  YearAndMonth,
} from "@revelate/types";
import { deleteCommission, upsertCommission } from "../supabase/commissions";
import {
  abbreviateLongString,
  capitalizeFirstLetter,
  yearMonthExistsInArray,
} from "@revelate/utils";
import { getCommissionsValue } from "@revelate/calc";
import { updateDeal } from "../supabase";
import { getCurrencyFormatted } from "./currencies";

export const groupCommissionsByMonth = (
  commissions: NestedCommission[]
): GroupedCommissions => {
  // First group by month-year
  const unordered = commissions.reduce(
    (acc: Record<string, NestedCommission[]>, commission) => {
      const key: string = `${commission.year}-${String(commission.month).padStart(2, "0")}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(commission);
      return acc;
    },
    {}
  );

  // Sort keys chronologically
  const orderedKeys = Object.keys(unordered).sort();

  // Create new ordered object
  return orderedKeys.reduce((acc: GroupedCommissions, key) => {
    acc[key] = unordered[key];
    return acc;
  }, {});
};

const allDealsLabel = "Additional commissions";

const getDealLabel = (commission: NestedCommission) => {
  const { accelerator } = commission || {};
  const { commission_based_on, fixed_value_amount } = accelerator || {};
  const { plan, value_actual, value_target } = commission || {};
  const { time_period, deal_role } = plan || {};
  const timePeriod = capitalizeFirstLetter(time_period || "");
  if (deal_role === "management") {
    return `${timePeriod}ly management bonuses`;
  }
  if (deal_role === "collaborator") {
    return `${timePeriod}ly deal collaborator commissions`;
  }
  if (commission_based_on === "fixed_value" && fixed_value_amount) {
    return `${timePeriod}ly fixed amount commissions`;
  }
  if (
    commission_based_on === "deal_value_above_target" &&
    value_actual &&
    value_target
  ) {
    return `${timePeriod}ly above sales target (${getCurrencyFormatted(value_target)})`;
  }
  if (
    commission_based_on === "deal_value_below_target" &&
    value_actual &&
    value_target
  ) {
    return `${timePeriod}ly below sales target (${getCurrencyFormatted(value_target)})`;
  }
  return `${capitalizeFirstLetter(commission_based_on?.replaceAll("_", " ") || allDealsLabel)}`;
};

export const groupCommissionsByDealName = (
  commissions: NestedCommission[]
): GroupedCommissions => {
  return commissions
    .toSorted((a, b) =>
      a.deal && b.deal && a.deal.closed_at && b.deal.closed_at
        ? (new Date(a.deal.closed_at) as TODO) -
          (new Date(b.deal.closed_at) as TODO)
        : a.deal && a.deal.closed_at
          ? -1
          : 1
    )
    .reduce((acc: TODO, commission) => {
      const key: string = commission.deal
        ? abbreviateLongString(commission?.deal?.name, 30)
        : getDealLabel(commission);
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(commission);
      return acc;
    }, {});
};

export const groupCommissionsByUserId = (
  commissions: NestedCommission[]
): GroupedCommissions => {
  const grouped: GroupedCommissions = {};

  for (const c of commissions) {
    if (Object.keys(grouped).includes(c.user_id)) {
      grouped[c.user_id] = [...grouped[c.user_id], c];
    } else {
      grouped[c.user_id] = [c];
    }
  }

  return grouped;
};

export const combineEarnedAndProjectedCommissionMonths = (
  earnedCommissionsByMonth: CommissionMonth[],
  projectedCommissionsByMonth: CommissionMonth[]
): CommissionMonthWithProjected[] => {
  const combinedMonths: CommissionMonthWithProjected[] = [];

  // First add all months where commissions are earned
  for (const earnedCommissionMonth of earnedCommissionsByMonth) {
    const projectedCommissionForMatchingMonth =
      projectedCommissionsByMonth.find(
        (p) =>
          p.year === earnedCommissionMonth.year &&
          p.month === earnedCommissionMonth.month
      );
    if (projectedCommissionForMatchingMonth) {
      combinedMonths.push({
        ...earnedCommissionMonth,
        projectedValue: projectedCommissionForMatchingMonth.value,
      });
    } else {
      combinedMonths.push({
        ...earnedCommissionMonth,
        projectedValue: 0,
      });
    }
  }

  // Then catch all the months with no earned and only projected commissions
  for (const projectedCommissionMonth of projectedCommissionsByMonth) {
    const alreadyAddedMonths: YearAndMonth[] = combinedMonths.map((e) => ({
      year: e.year,
      month: e.month,
    }));

    const checkingMonth: YearAndMonth = {
      year: projectedCommissionMonth.year,
      month: projectedCommissionMonth.month,
    };

    if (yearMonthExistsInArray(checkingMonth, alreadyAddedMonths)) {
      continue;
    }

    // <value> represents "earnedValue" for combined months
    combinedMonths.push({
      ...projectedCommissionMonth,
      value: 0,
      projectedValue: projectedCommissionMonth.value,
    });
  }

  return combinedMonths;
};

export const isUnapproved = (commissions: NestedCommission[]) => {
  return commissions.some((c) => c.status === "unapproved");
};

export const getUnapproved = (commissions: NestedCommission[]) => {
  return commissions.filter((c) => c.status === "unapproved");
};

export const getAggregateEarned = (commissions: NestedCommission[]) => {
  return commissions.filter(
    (c) =>
      c.status === "unapproved" ||
      c.status === "approved" ||
      c.status === "paid"
  );
};

export const getDealCount = (commissions: NestedCommission[]) => {
  return new Set(commissions.map((c) => c.deal_id)).size;
};

export const isApproved = (commissions: NestedCommission[]) => {
  return commissions.some((c) => c.status === "approved");
};

export const getApproved = (commissions: NestedCommission[]) => {
  return commissions.filter((c) => c.status === "approved");
};

export const getAggregateApproved = (commissions: NestedCommission[]) => {
  return commissions.filter(
    (c) => c.status === "approved" || c.status === "paid"
  );
};

export const isPaid = (commissions: NestedCommission[]) => {
  return commissions.find((c) => c.status === "paid");
};

export const getPaid = (commissions: NestedCommission[]) => {
  return commissions.filter((c) => c.status === "paid");
};

export const isRejected = (commissions: NestedCommission[]) => {
  return commissions.find((c) => c.status === "rejected");
};

export const getBadgeTitle = (commissions: NestedCommission[]) => {
  if (isUnapproved(commissions))
    return `${getUnapproved(commissions).length} of ${
      commissions.length
    } needs approval`;
  if (isApproved(commissions))
    return `${getApproved(commissions).length} of ${
      commissions.length
    } left to pay`;
  if (isPaid(commissions)) return `Completed`;
};

export const getBadgeVariant = (commissions?: NestedCommission[]) => {
  if (
    !commissions ||
    commissions.length === 0 ||
    getCommissionsValue(commissions) === 0
  )
    return "secondary";
  if (isUnapproved(commissions)) return "unapproved";
  if (isApproved(commissions)) return "approved";
  if (isPaid(commissions)) return "paid";
  if (isRejected(commissions)) return "rejected";
  return "unapproved";
};

export const getBadgeSummary = (commissions?: NestedCommission[]) => {
  if (
    !commissions ||
    commissions.length === 0 ||
    getCommissionsValue(commissions) === 0
  )
    return "N/A";
  if (isUnapproved(commissions)) return "To approve";
  if (isApproved(commissions)) return "To pay";
  if (isPaid(commissions)) return "Done";
  if (isRejected(commissions)) return "Rejected";
  return "N/A";
};

export const updateCommissionsStatus = async (
  commissions: NestedCommission[],
  previousStatus: CommissionStatus,
  newStatus: CommissionStatus,
  filterByCommission?: NestedCommission
) => {
  const filteredCommissions = commissions.filter(
    (c) =>
      c.status === previousStatus &&
      ((filterByCommission &&
        // c.deal_id === filterByCommission.deal_id &&
        // c.plan_id === filterByCommission.plan_id &&
        c.year === filterByCommission.year &&
        c.month === filterByCommission.month) ||
        !filterByCommission)
  );
  const updated = filteredCommissions.map((c) => {
    return {
      ...c,
      status: newStatus,
      plan: undefined,
      deal: undefined,
      accelerator: undefined,
      user: undefined,
    };
  });
  const isPersisted = newStatus !== "unapproved";
  await Promise.all(
    updated.map((c) =>
      isPersisted ? upsertCommission(c) : c.id ? deleteCommission(c.id) : null
    )
  );
  // Update read-only status on deals
  const isReadOnly = newStatus === "approved" || newStatus === "paid";
  await Promise.all(
    updated.map((c) =>
      c.deal_id ? updateDeal(c.deal_id, { is_read_only: isReadOnly }) : null
    )
  );
};
