import { Separator } from "@/components/ui/separator";
import { GeneralForm } from "./general-form";
import {
  NestedCurrency,
  NestedQuota,
  NestedToken,
  SignedInCompany,
} from "@revelate/types";
import type { KeyedMutator } from "swr";

export const GeneralPage = ({
  quota,
  tokens,
  company,
  currencies,
  mutate,
}: {
  quota?: NestedQuota;
  tokens?: NestedToken[];
  company?: SignedInCompany;
  currencies?: NestedCurrency[];
  mutate: KeyedMutator<NestedQuota | null>;
}) => {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">General</h3>
        <p className="text-sm text-muted-foreground">
          Set the basics of your sales quota.
        </p>
      </div>
      <Separator />
      <GeneralForm
        quota={quota}
        tokens={tokens}
        company={company}
        currencies={currencies}
        mutate={mutate}
      />
    </div>
  );
};
