// import Image from "next/image";
// import Link from "next/link";
import { Check } from "lucide-react";

import { Badge } from "@/components/ui/badge";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useAppStore } from "@/stores";
import useSWR from "swr";
import { Loader } from "@/components/Loader";
import { LoadingError } from "@/components/LoadingError";
import { sortByStringKey } from "@revelate/utils";
import { TableType, get, getAll, updateCompanyCurrency } from "@/lib/supabase";
import { NestedCurrency } from "@revelate/types";
import { format } from "date-fns";
import {
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
  Tooltip,
} from "@/components/ui/tooltip";
import { getExchangeRateToDefaultCurrency, isDefaultCurrency } from "@/lib/app";
import { ExchangeRatesForm } from "./components/exchange-rates-form";
import { ErrorWithRetry } from "@/components/ErrorWithRetry";

export function CurrenciesPage() {
  const currentUser = useAppStore((state) => state.currentUser);
  const {
    data: company,
    error: companyError,
    isLoading: companyIsLoading,
    mutate: mutateCompany,
  } = useSWR(
    [
      "companies" as TableType,
      currentUser ? currentUser?.company_id?.toString() : null,
    ],
    ([table, companyId]) => get(table, companyId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const {
    data: currencies,
    error,
    isLoading,
    mutate,
  } = useSWR(
    ["currencies" as TableType, company ? company?.id.toString() : null],
    ([table, companyId]) => getAll(table, companyId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const updateCurrency = async (currencyId: number) => {
    if (!company) return;
    const newCompany = await updateCompanyCurrency(company.id, currencyId);
    if (newCompany) {
      mutateCompany(newCompany);
    }
  };

  if (isLoading || companyIsLoading || !currencies || !company) {
    return <Loader />;
  }
  if (error || companyError) {
    return <ErrorWithRetry error={error.message || companyError.message} onRetry={() => window.location.reload()} />;
  }

  return (
    <main className="grid flex-1 items-start gap-4  sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-3">
        <Card x-chunk="dashboard-05-chunk-3">
          <div className="flex">
            <CardHeader className="flex px-7">
              <CardTitle>Currencies</CardTitle>
              <CardDescription>
                Manage default currency and exchange rates for your deals.
              </CardDescription>
            </CardHeader>
          </div>
          <CardContent>
            {currencies && currencies.length > 0 ? (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Currency Code</TableHead>

                    <TableHead className="hidden sm:table-cell">
                      Date added
                    </TableHead>
                    <TableHead className="hidden sm:table-cell">
                      Exchange rate to {company?.default_currency?.code}
                    </TableHead>
                    <TableHead className="hidden sm:table-cell">
                      Valid until
                    </TableHead>

                    <TableHead className="text-right"></TableHead>
                    <TableHead></TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {(currencies as NestedCurrency[])
                    ?.sort(sortByStringKey("code"))
                    .map((currency, index) => {
                      const exchangeRate = getExchangeRateToDefaultCurrency(
                        currency,
                        company.default_currency
                      );
                      const defaultCurrency = isDefaultCurrency(
                        company,
                        currency.id
                      );
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <div className="font-medium">
                              {currency.code}
                              {isDefaultCurrency(company, currency.id) && (
                                <Badge variant="secondary" className="ml-2">
                                  Default
                                </Badge>
                              )}
                            </div>
                            <div className="hidden text-sm text-muted-foreground md:inline">
                              {" "}
                              {defaultCurrency
                                ? "Company Currency"
                                : "Deal Currency"}
                            </div>
                          </TableCell>

                          <TableCell className="hidden sm:table-cell">
                            {format(
                              new Date(currency.created_at),
                              "MMM dd, yyyy"
                            )}
                          </TableCell>
                          <TableCell className="hidden sm:table-cell">
                            {!defaultCurrency && (
                              <Badge
                                variant={
                                  exchangeRate ? "secondary" : "destructive"
                                }
                              >
                                {exchangeRate
                                  ? `1 ${currency.code} = ${exchangeRate?.rate} ${company?.default_currency?.code}`
                                  : "Missing"}
                              </Badge>
                            )}
                          </TableCell>
                          <TableCell className="hidden sm:table-cell">
                            {" "}
                            {exchangeRate && exchangeRate.valid_to
                              ? format(
                                  new Date(exchangeRate?.valid_to || ""),
                                  "MMM dd, yyyy"
                                )
                              : ""}
                          </TableCell>

                          <TableCell>
                            <div className="flex items-center gap-3">
                              <ExchangeRatesForm
                                company={company}
                                currency={currency}
                                exchangeRate={exchangeRate}
                                mutate={mutate}
                              />
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipContent>
                                    Set {currency.code} as default currency
                                  </TooltipContent>
                                  <TooltipTrigger asChild>
                                    {!defaultCurrency && (
                                      <Button
                                        onClick={() =>
                                          updateCurrency(currency.id)
                                        }
                                        variant="secondary"
                                        size="icon"
                                      >
                                        <Check className="h-4 w-4" />
                                      </Button>
                                    )}
                                  </TooltipTrigger>
                                </Tooltip>
                              </TooltipProvider>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            ) : (
              <p className="text-lg text-muted-foreground text-center">
                Sync deals to auto-populate currencies 💸
              </p>
            )}
          </CardContent>
        </Card>
      </div>
    </main>
  );
}
