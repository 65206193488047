import "./App.css";
import { useAuth } from "@saas-ui/auth";
import { Loader } from "./components/Loader";
import { useAppStore } from "./stores";
import { useEffect } from "react";
import AuthenticationPage from "./authentication/page";
import { Layout } from "./layout/page";
import { supabaseClient } from "./lib/supabase/client";
import { MissingUserRole } from "./layout/components/missing-user-role";
import { useLocation } from "wouter";
import { CommissionsProvider } from "@/providers/CommissionsProvider";

function App() {
  const auth = useAuth();
  const { isLoaded, fetch, reset, currentUser } = useAppStore();

  // const [match] = useRoute("/signout");
  const [location, setLocation] = useLocation();

  useEffect(() => {
    if (location === "/logout") {
      auth.logOut();
      reset();
      setLocation("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const { data: authListener } = supabaseClient.auth.onAuthStateChange(
      async (_event, session) => {
        // reset();
        fetch(session);
      }
    );

    return () => {
      authListener.subscription;
    };
  }, []);

  return !auth || auth.isLoggingIn ? (
    <Loader />
  ) : auth.isAuthenticated ? (
    isLoaded() ? (
      <CommissionsProvider>
        <Layout />
      </CommissionsProvider>
    ) : currentUser && currentUser.role === null ? (
      <CommissionsProvider>
        <MissingUserRole />
      </CommissionsProvider>
    ) : (
      <Loader />
    )
  ) : (
    <CommissionsProvider>
      <AuthenticationPage />
    </CommissionsProvider>
  );
}

export default App;
