// import Image from "next/image";
// import Link from "next/link";
import { Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useAppStore } from "@/stores";
import { useLocation } from "wouter";
import useSWR from "swr";
import { Loader } from "@/components/Loader";
import { LoadingError } from "@/components/LoadingError";
import { getRoute } from "@/lib/routes";
import { deleteQuota } from "@/lib/supabase/quotas";
import { TableType, getAll } from "@/lib/supabase";
import {
  QuotasTable,
  createQuotasTableColumns,
} from "./components/quotas-table";
import { ErrorWithRetry } from "@/components/ErrorWithRetry";

export function QuotasPage() {
  const appState = useAppStore((state) => state);
  const { currentUser } = appState;
  const {
    data: quotas,
    error,
    isLoading,
    mutate,
  } = useSWR(
    [
      "quotas" as TableType,
      currentUser ? currentUser?.company_id.toString() : null,
      true,
      currentUser?.role,
    ],
    ([table, companyId, activeOnly, userRole]) => getAll(table, companyId, activeOnly, userRole),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const quotasTableColumns = createQuotasTableColumns({
    goToQuota: (id: string) => setLocation(getRoute("quotas", "quota", id)),
    onDeleteQuota: async (id: string) => {
      await deleteQuota(Number(id));
      const updatedQuotas = quotas?.filter((quota) => quota.id !== Number(id));
      mutate(updatedQuotas);
    },
  });

  const [, setLocation] = useLocation();

  if (isLoading || !quotas) {
    return <Loader />;
  }
  if (error) {
    return <ErrorWithRetry error={error.message} onRetry={() => window.location.reload()} />;
  }

  return (
    <main className="grid flex-1 items-start gap-4  sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-3">
        <Card x-chunk="dashboard-05-chunk-3">
          <div className="flex">
            <CardHeader className="flex px-7">
              <CardTitle>Sales Quotas</CardTitle>
              <CardDescription>
                Track target attainment for your sales team.
              </CardDescription>
            </CardHeader>
            <div className="ml-auto flex items-center gap-2 pr-7">
              <Button
                onClick={() => setLocation(getRoute("quotas", "quota", "new"))}
                className="bg-[#6B6397]"
              >
                <Plus className="mr-2 h-4 w-4" />
                Add new quota
              </Button>
            </div>
          </div>
          <CardContent>
            {quotas && quotas.length > 0 ? (
              <QuotasTable quotas={quotas} columns={quotasTableColumns} />
            ) : (
              <p className="text-lg text-muted-foreground text-center">
                Add your first quota to personalize sales goals 🎯
              </p>
            )}
          </CardContent>
        </Card>
      </div>
    </main>
  );
}
